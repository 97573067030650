import React from "react";
import Layout from "../../components/Layout";
import Banner from "./components/Banner";
import Description from "./components/Description";
import TheGia from "./components/TheGia";

const EmeraldFacts = () => {
	return (
		<Layout>
			<Banner />
			<TheGia />
			<Description />
		</Layout>
	);
};

export default EmeraldFacts;
