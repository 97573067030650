import React from "react";
import FadeInUpInline from "../../../components/FadeInUpInline";

const Description = () => {
	return (
		<section className="py-20 md:py-[113px]">
			<div className="container max-w-[1200px]">
				<div className="emerald-descriptions">
					<div className="item">
						<FadeInUpInline>
							<h2 className="title">What Emeralds Are and Are Not</h2>
						</FadeInUpInline>
						<FadeInUpInline>
							<div className="txt-1">
								This is the gem of ten thousand legends, praised in
								Sanskrit stories before the Pyramids. Beryl, the
								chemical birthplace of emeralds, comes in many colors,
								but the most treasured and rare for millenniums is the
								mystical emerald with its intense green radiance.
							</div>
						</FadeInUpInline>
						<FadeInUpInline>
							<div className="txt-1">
								The dazzling color and enchanting character of emeralds
								come from chromium and vanadium impurities that have
								invaded an otherwise colorless crystal structure,
								replacing aluminum. At their essence, emeralds are not
								meant to be perfect crystals. A perfect diamond is just
								carbon. But, the perfect emerald is an artist’s dazzling
								dance, created in the crucible of the chemically
								impossible.
							</div>
						</FadeInUpInline>
					</div>
					<div className="item">
						<FadeInUpInline>
							<h2 className="title">What Makes an Emerald Valuable?</h2>
						</FadeInUpInline>
						<FadeInUpInline>
							<div className="txt-1 flex gap-4">
								<div className="w-0 flex-grow xl:pt-2">
									While our attraction to the beautiful green gem is
									natural, emerald formation is a mysterious geological
									miracle. Two of the rarest earth elements–beryllium
									from the high continents and chromium from the seabed
									must collide under the perfect nexus of pressure,
									moisture and blast furnace temperatures to create
									these exquisite treasures. Diamond formation is a
									parlor trick compared to this miracle.
								</div>
								<img
									src="/img/emerald/description-1.png"
									className="w-[300px] xl:w-[381px] hidden lg:block"
									alt=""
								/>
							</div>
						</FadeInUpInline>
					</div>
					<div className="item">
						<FadeInUpInline>
							<h2 className="title">Judging Emerald Quality</h2>
						</FadeInUpInline>
						<FadeInUpInline>
							<div className="txt-1">
								Color reigns supreme. A vividly saturated true green hue
								rules when it comes to judging emerald quality. And a
								medium to deep tone is its perfect complement. These two
								form the royal couple, which dominate all else in
								emerald evaluation.
							</div>
						</FadeInUpInline>
						<FadeInUpInline>
							<div className="txt-1">
								Clarity is next in line--the crown prince. The perfect
								prince is a rare gem indeed. But perfection isn’t
								everything. Even prominently included stones can be
								valuable treasures for their color, character and
								uniqueness.
							</div>
						</FadeInUpInline>
						<FadeInUpInline>
							<div className="txt-1">
								Cut is the princess of the family. It can make the stone
								dance with every movement, or leave it dreary. Proper
								proportions and expert polishing create brilliance
								inside the stone that brings it to life.
							</div>
						</FadeInUpInline>
					</div>
					<div className="item">
						<FadeInUpInline>
							<h2 className="title">What Is Emerald Finishing?</h2>
						</FadeInUpInline>
						<FadeInUpInline>
							<div className="txt-2">
								The green color is born in 1000ºF soup by replacing
								light weight aluminum with chromium, twice its atomic
								number and atomic mass. As such, the formation of
								emeralds creates stress lines and "inclusions" as a
								natural part of the miraculous formation.
							</div>
						</FadeInUpInline>
						<FadeInUpInline>
							<div className="txt-2">
								For that reason, dating back literally to the pyramids,
								emeralds are finished with a stress line "filler" 90-95%
								of the time. Virtually every emerald you see is
								"treated" or "oiled." The fillers enhance the clarity of
								the gem and prevent foreign substances from entering the
								stress fissures. Untreated stones without inclusions or
								stress lines are incredibly rare and demand a
								dramatically higher price per carat, creating some
								instances where a stone was certified as untreated and
								was later treated without updating the certificate.
							</div>
						</FadeInUpInline>
						<FadeInUpInline>
							<div className="txt-2">
								Other "emeralds" are lab created or grown, and have
								little to no true value. Panjshir Valley Emeralds are
								the real deal, as nature has smiled upon the Hindu Kush
								mountains, bringing the sea bed to the mountain tops to
								create these extraordinary pieces of art called
								emeralds.
							</div>
						</FadeInUpInline>
						<FadeInUpInline>
							<div className="txt-2">
								Many types of enhancements are used in the marketplace.
								A good enhancement is both long-lasting and able to be
								removed and re-applied.
							</div>
						</FadeInUpInline>
						<FadeInUpInline>
							<div className="txt-2">
								Oil enhancements are easily removable but also
								deteriorate rather quickly.
							</div>
						</FadeInUpInline>
						<FadeInUpInline>
							<div className="txt-2">
								Hardened or permanent resin fillers can last for a long
								time, but no filler is truly as permanent as a timeless
								emerald. The difficulty of removing these fillers when
								they eventually do deteriorate makes them not ideal.
							</div>
						</FadeInUpInline>
						<FadeInUpInline>
							<div className="txt-2">
								The ExCel process in a proprietary process performed in
								the United States. The enhancement will last an entire
								lifetime, but can be removed and re-applied or replaced
								with a different enhancement at any time.
							</div>
						</FadeInUpInline>
						<FadeInUpInline>
							<div className="txt-2">
								For the reasons above, we evaluate each gemstone and
								decide which treatment, or no treatment, is best to
								allow its natural beauty shine through. We offer stones
								with no-treatment, oil treatment, and ExCel process
								treatment, but for most stones we use and recommend the
								ExCel process for its combination of beauty, longevity,
								and removability.
							</div>
						</FadeInUpInline>
					</div>
					<div className="item">
						<FadeInUpInline>
							<h2 className="title">Where Do Emeralds Come From?</h2>
						</FadeInUpInline>
						<FadeInUpInline>
							<div className="txt-1 flex gap-6">
								<img
									src="/img/emerald/description-2.png"
									className="w-[300px] xl:w-[417px] hidden lg:block"
									alt=""
								/>
								<div className="w-0 flex-grow xl:pt-2">
									Emerald deposits are rare treasures—but not all are
									created equal. There are two primary types of
									deposits. Igneous rock deposits are found in
									countries such as, Russia, the United States, Brazil,
									Zambia, Ethiopia, and Pakistan. Viable sedimentary
									rock deposits on the the other hand are only known to
									exist in two places on earth, the Columbian Andes,
									and the Hindu Kush of the Panjshir Valley.
								</div>
							</div>
						</FadeInUpInline>
					</div>
					<div className="item">
						<FadeInUpInline>
							<div className="txt-2">
								While there are many unanswered questions, scientists
								believe that these sedimentary deposits formed at
								slightly lower temperatures and with an extra-rich
								supply of chromium, compared to their igneous cousins.
								The details of formation may be mysterious, but one
								thing is clear—sedimentary emerald deposits contain the
								world’s purest and greenest emeralds.
							</div>
						</FadeInUpInline>
						<FadeInUpInline>
							<div className="txt-2">
								When the two sedimentary deposits are compared side by
								side, emeralds from Panjshir are revealed to have
								significantly higher chromium content–making them the
								new standard in emerald color.
							</div>
						</FadeInUpInline>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Description;
