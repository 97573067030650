import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/Layout";
import Banner from "./components/Banner";
import FactsAboutEmeralds from "./components/FactsAboutEmeralds";
import VideoSection from "./components/VideoSection";
import WhyUs from "./components/WhyUs";

const Home = () => {
	return (
		<Layout>
			<Banner />
			<VideoSection />
			<FactsAboutEmeralds />
			<WhyUs />
			<div className="container py-10 flex justify-center">
				<Link
					to="/shop-emeralds"
					className="flex items-center font-semibold text-xl text-white tracking-[4.88px] md:tracking-[10px] py-3 md:min-h-[67px] px-9 bg-dark rounded-full"
				>
					SHOP EMERALDS
				</Link>
			</div>
		</Layout>
	);
};

export default Home;
