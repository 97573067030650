import React from "react";
import { Link } from "react-router-dom";
import { icons } from "./icons";

const Footer = () => {
	return (
		<footer className="bg-footer pt-16 lg:pt-[120px] pb-3 md:pb-[42px]">
			<div className="container">
				<div className="flex justify-evenly items-end flex-wrap">
					<div className="w-full pb-8 lg:pb-0 border-b border-black lg:border-none lg:w-1/2 lg:max-w-[419px]">
						<Link
							to="/"
							className="block w-3/4 sm:w-full max-w-[200px] md:max-w-[250px] mx-auto mb-6 md:mb-8	"
						>
							<img
								src="/img/footer-logo.png"
								className="w-full invert"
								alt=""
							/>
						</Link>
						<div className="text-center text-sm sm:text-base mb-8 tracking-[0.05em]">
							Our unique access brings you the highest quality emeralds
							from the Panjshir Valley, Afghanistan
						</div>
						<img
							src="/img/trust.png"
							className="max-w-[65%] mx-auto mb-8 md:mb-12"
							alt=""
						/>
						<div className="text-center text-sm font-light mb-8 md:mb-[47px] tracking-[0.05em]">
							To learn about our fully-traceable supply chain, visit{" "}
							<Link to="" className="md:underline">
								TrustedBlockchain.com
							</Link>
						</div>
						<div className="footer-secure-icons mb-10">
							<div className="flex justify-center items-center gap-2">
								{icons.secure}
								Secure payments
							</div>
							<div className="flex justify-center items-center gap-2">
								{icons.lock}Privacy Protected
							</div>
						</div>
						<div className="font-light md:font-normal flex flex-wrap gap-8 justify-center text-[13px] tracking-[0.05em]">
							<Link to="">Terms & Conditions</Link>
							<Link to="">Privacy Policy</Link>
						</div>
					</div>
					<div className="text-center tracking-[0.05em] pt-5 md:pt-10 md:pb-20 lg:w-1/2 lg:max-w-[419px]">
						<h4 className="text-[13px] md:text-lg md:font-medium text-dark font-Roboto mb-[14px] md:mb-10 xl:mb-[110px]">
							Washington, D.C. <br /> Dubai
						</h4>
						<div className="flex flex-col md:flex-row justify-center gap-[14px] md:gap-x-7 mb-[14px] md:mb-[34px] text-[13px] md:text-md">
							<Link to="mailto:demo@example.com">Email link</Link>
							<Link to="tel:+1 202 802 8072">+1 202 802 8072</Link>
						</div>
						<div className="flex justify-center gap-[18px] md:gap-10">
							<Link to="">
								<img
									className="w-[15px] md:w-[25px]"
									src="/img/social/linkedin.png"
									alt=""
								/>
							</Link>
							<Link to="">
								<img
									className="w-[15px] md:w-[25px]"
									src="/img/social/x.png"
									alt=""
								/>
							</Link>
							<Link to="">
								<img
									className="w-[15px] md:w-[25px]"
									src="/img/social/insta.png"
									alt=""
								/>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
