import React from "react";
import { Link } from "react-router-dom";
import FadeInUpInline from "../../../components/FadeInUpInline";

const WhyUs = () => {
	return (
		<section
			style={{
				background:
					"url(/img/home/why.png) no-repeat center center / cover fixed",
			}}
		>
			{data.map((item, index) => (
				<WhyUsItem index={index} item={item} key={index} />
			))}
		</section>
	);
};
const WhyUsItem = ({ item, index }) => {
	return (
		<FadeInUpInline>
			<div className="min-h-[calc(100vh-150px)] md:min-h-[calc(100vh-84px)] pb-[60px] pt-20 md:pb-[100px] relative flex flex-col">
				<div className="text-black text-opacity-[0.44] absolute top-[100px] md:top-[206px] w-full left-0 text-[200px] font-Orpheus leading-[1] text-center font-normal">
					<FadeInUpInline fromTop>
						<div>{index + 1}</div>
					</FadeInUpInline>
				</div>
				<div className="px-4 relative text-center">
					<FadeInUpInline>
						<h1 className="text-[36px] leading-[1] lg:text-[75px] md:mb-[67px] text-white mb-12 lg:mb-[106px]">
							WHY US?
						</h1>
					</FadeInUpInline>
					<FadeInUpInline>
						<p className="text-[28px] leading-[1] tracking-[1.12px] md:text-[40px] text-white font-Orpheus md:tracking-[2.56px] xl:text-[64px] xl:leading-[1] font-normal">
							{item.text}
						</p>
					</FadeInUpInline>
				</div>
				<div className="px-4 mt-auto">
					<FadeInUpInline>
						<img
							src="/img/footer-logo.png"
							className="w-1/2 sm:w-full max-w-[380px] mx-auto mt-20 lg:mt-[150px]"
							alt=""
						/>
					</FadeInUpInline>
				</div>
				{index === data.length - 1 && (
					<div className="mt-auto text-center pt-20">
						<Link to="/shop-emeralds" className="btn">
							LEARN MORE
						</Link>
					</div>
				)}
			</div>
		</FadeInUpInline>
	);
};
const data = [
	{
		text: "The Panjshir Valley offers the greenest emeralds on earth",
	},
	{
		text: "We have unique access to the best of them",
	},
	{
		text: "We cut artistry, the dancing hall of mirrors, not weight",
	},
	{
		text: "We’re bringing integrity to th gemstone supply chain",
	},
	{
		text: "YOU can partner with the villagers themselves!",
	},
];

export default WhyUs;
