import React from "react";
import FadeInUpInline from "../../../components/FadeInUpInline";

const Partnership = () => {
	return (
		<section className="bg-black py-20 md:py-[120px] xl:pt-[180px] xl:pb-[160px]">
			<div className="container xl:max-w-[1100px]">
				<div className="font-Orpheus text-center text-white txt-2xl md:text-3xl lg:leading-[40px] flex flex-col gap-8">
					<FadeInUpInline>
						<div className="">
							When you purchase emeralds from Panjshir Valley Emeralds, a
							percentage of the proceeds goes directly to the people of
							Afghanistan.
						</div>
					</FadeInUpInline>
					<FadeInUpInline>
						<div className="">
							This partnership will be verifiable by our BlockChain and
							dual Biometric signatures at the village level so that you
							can be sure that funds make it to where they should.
						</div>
					</FadeInUpInline>
					<FadeInUpInline>
						<div className="">
							Learn more about some of the villages that benefit from
							your purchase below.
						</div>
					</FadeInUpInline>
				</div>
			</div>
		</section>
	);
};

export default Partnership;
