import { animated, useSpring } from "@react-spring/web";
import React, { useEffect, useRef, useState } from "react";
const FadeIn = ({ children }) => {
	const [isVisible, setIsVisible] = useState(false);
	const ref = useRef();
	const handleScroll = () => {
		const refBottom = ref.current.getBoundingClientRect().top;
		const displayBottom = window.innerHeight;
		setIsVisible(refBottom < displayBottom - 100);
	};
	useEffect(() => {
		handleScroll();
		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, []);

	const styles = useSpring({
		transition: "all ease 0.4s",
		opacity: isVisible ? 1 : 0.4,
	});

	return (
		<animated.div style={styles} ref={ref}>
			{children}
		</animated.div>
	);
};
export default FadeIn;
