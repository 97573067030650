import React from "react";
import FadeInUpInline from "../../../components/FadeInUpInline";

const WhyUsSection = () => {
	return (
		<section className="border-t-[9px] border-white">
			<div className="bg-dark py-12 md:py-20">
				<div className="container max-w-[1050px]">
					<FadeInUpInline>
						<h2 className="text-white village-title">
							1. The Panjshir Valley offers the greenest emeralds on
							earth.
						</h2>
					</FadeInUpInline>
					<div className="flex flex-wrap md:flex-nowrap justify-center md:justify-between gap-y-10">
						<div className="w-full md:w-3/4 md:max-w-[520px] flex flex-col gap-10 xl:gap-[60px] text-white text-md lg:text-xl lg:mt-8">
							<FadeInUpInline>
								<div className="">
									The purest, boldest “emerald green,” is the careful
									balance between hue, saturation, and brightness that
									only the world's best emeralds possess. Science
									shares that extraordinary "kryptonite" color only
									with emeralds formed in sedimentary rock, allowing
									enough chromium content to make the gems sing.
								</div>
							</FadeInUpInline>
							<FadeInUpInline>
								<div className="">
									Gubelin Labs says the best Panjshir emeralds are
									comparable to the very finest of Colombian emeralds.
									To understand why, you need look no further than the
									world’s densest chromium content uniquely in the
									sedimentary rock formations of the Panjshir Valley.
								</div>
							</FadeInUpInline>
						</div>
						<div className="w-full md:w-1/2 max-w-[350px] md:max-w-[380px]">
							<FadeInUpInline>
								<img
									src="/img/why-us/1.png"
									className="max-w-full md:ml-auto"
									alt=""
								/>
							</FadeInUpInline>
						</div>
					</div>
				</div>
			</div>
			<div className="py-12 md:py-20">
				<div className="container xl:max-w-[1140px]">
					<FadeInUpInline>
						<h2 className="text-primary village-title xl:mb-[60px]">
							2. We have unique access to the best Panjshir Valley
							emeralds.
						</h2>
					</FadeInUpInline>
					<div className="flex flex-col gap-10 xl:gap-[60px] text-md lg:text-lg xl:leading-[30px] lg:mt-8 font-light">
						<FadeInUpInline>
							<div>
								Historically, the legendary Panjshir Valley emerald has
								only rarely come to the market, while other countries
								have exploited their mines in a rush to deliver goods.
								Today, in a new generation of applied technology and
								people-sensitive morals, we now have an unprecedented
								opportunity together to bring the best the earth has
								ever had to offer to those who love the dance of natural
								beauty. Here's how we can provide the best quality
								emeralds on a consistent basis:
							</div>
						</FadeInUpInline>
						<ul className="pl-4 xl:pr-4 flex flex-col gap-2">
							<FadeInUpInline>
								<li className="disc">
									First, we have a one-of-a-kind relationship with both
									the Afghan government and local tribal leaders. Our
									partnership gives us privileged access and
									right-of-first-refusal on all of their
									ethically-sourced gemstone rough.
								</li>
							</FadeInUpInline>
							<FadeInUpInline>
								<li className="disc">
									Secondly, we have outstanding support from our own
									government at the highest levels. Fair economic
									business partnerships with our friends in
									Afghanistan, and the resulting quality of life and
									stability, is a stated high priority by every
									administration, past and present. It's just not been
									done well until now. We are 50-50 partners, and
									unashamedly family, in trust and esteem.
								</li>
							</FadeInUpInline>
							<FadeInUpInline>
								<li className="disc">
									Finally, we have amazing technology coming to the
									mines and in our expanding, award-winning faceting
									locations, along with global networks and personal
									involvement from Harvard University, Oxford
									University, Colorado School of Mines, the Silicon
									Valley and more.
								</li>
							</FadeInUpInline>
						</ul>
					</div>
				</div>
			</div>
			<img src="/img/why-us/2.png" className="w-full" alt="" />
			<div
				className="py-12 md:py-20 lg:min-h-screen"
				style={{
					background:
						"url(/img/why-us/3.png) no-repeat center center / cover fixed",
				}}
			>
				<div className="container max-w-[1140px]">
					<FadeInUpInline>
						<h2 className="text-white village-title xl:mb-[99px]">
							3. We cut for beauty, not weight.
						</h2>
					</FadeInUpInline>
					<div className="flex flex-col gap-10 xl:gap-[60px] text-md lg:text-lg xl:leading-[30px] lg:mt-8 font-light text-white">
						<FadeInUpInline>
							<div>
								While others think you won't notice the "fat belly"
								emerald cut that bloats the weight for sales purposes,
								we take a different view. We believe that it is both
								more ethical and more sane to trust you, the recipient
								of these verdant masterpieces, to prefer our "hall of
								mirrors" faceting we train all of our artists to
								utilize.
							</div>
						</FadeInUpInline>
						<FadeInUpInline>
							<div>
								Sure, we give up some of the chargeable carat weight,
								but we can't apologize for the skill and wonder of the
								scientific refractive index angles we use to maximize
								the extraordinary elegance, energy, and charm of our
								emeralds. We cut for beauty, not weight, eliminating the
								lifeless windowing and extinction effect that
								accompanies deeper, “belly-cut” emeralds. We think
								you'll notice.
							</div>
						</FadeInUpInline>
					</div>
				</div>
			</div>
			<div
				className="py-12 md:py-20 lg:min-h-screen"
				style={{
					background:
						"url(/img/why-us/4.png) no-repeat center center / cover fixed",
				}}
			>
				<div className="container max-w-[1140px]">
					<FadeInUpInline>
						<h2 className="text-white village-title xl:mb-[131px]">
							4. We’re bringing integrity to the gemstone supply chain.
						</h2>
					</FadeInUpInline>
					<div className="flex flex-col gap-10 xl:gap-[60px] text-md lg:text-lg xl:leading-[30px] lg:mt-8 font-light text-white">
						<FadeInUpInline>
							<div>
								Historically, smuggling, crime, money laundering, and
								worse have exploited developing nations and robbed them
								of their own resources. Until 2013 there was never a
								solution available, since, for obvious reasons, border
								guards have serious limitations. Today, for the first
								time, we can help the people and countries previously
								ravaged by crime and theft... to prosper from the ground
								they and their families stand on.
							</div>
						</FadeInUpInline>
						<FadeInUpInline>
							<div>
								Our Team, with Harvard and Oxford Universities'
								resources in BlockChain technology, is doing something
								about developing nations' loss of revenue for
								infrastructure and school building, due to smuggling.
								This is a unique "use-case" not seen before in
								BlockChain ("the Internet of Value and Security"). For
								more information, visit TrustedBlockchain.com
							</div>
						</FadeInUpInline>
						<FadeInUpInline>
							<div>
								Secondly, we are doing something about the lack of fair
								trade and citizens being able to care for their
								families--while wealth is removed all around them. We
								are your partners and partners of the people, bringing
								the highest level of accountability the emerald and
								gemstone industry has ever seen. And we are doing this
								to help developing nations and families. Some use the
								word BlockChain simply for marketing their goods and
								helping their own business with a buzzword. We should
								all want to provide true help for changing the quality
								of life for the developing countries where so many
								colored gemstones originate.
							</div>
						</FadeInUpInline>
					</div>
				</div>
			</div>
			<div
				className="py-12 md:py-[120px] lg:min-h-screen flex flex-wrap items-end justify-center"
				style={{
					background:
						"url(/img/why-us/5.png) no-repeat center center / cover fixed",
				}}
			>
				<div className="container xl:max-w-[1040px]">
					<div className="bg-black bg-opacity-40 p-6 xl:p-12">
						<FadeInUpInline>
							<h2 className="text-white village-title mb-9">
								5. We’re putting the needs of people before profits.
							</h2>
						</FadeInUpInline>
						<div className="flex flex-col gap-10 xl:gap-[50px] text-md lg:text-lg xl:leading-[28px] lg:mt-8 font-light text-white">
							<FadeInUpInline>
								<div>
									While the gemstone industry has been fraught with
									ethical issues, we at Panjshir Valley Emeralds and
									Trusted Inc are spearheading and passionate about
									ethical sourcing, distribution, and sales of
									world-class emeralds, and soon, other best-in-class
									gemstones.
								</div>
							</FadeInUpInline>
							<FadeInUpInline>
								<div>
									<div>
										We're devoted to making certain that the country
										and her people benefit from the natural resources
										under their feet. That's why we're driving the
										value back towards those who deserve to benefit
										from it through our "Partner-With-a-Village"
										program, which will connect emerald buyers to the
										lives of real people in Panjshir Valley.
									</div>
									<div className="mt-6">
										We have a lot more that we'd like to share about
										this, so click here on "Villages" or in the menu.
									</div>
								</div>
							</FadeInUpInline>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default WhyUsSection;
