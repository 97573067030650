import React from "react";
import Layout from "../../components/Layout";
import Banner from "./components/Banner";
import Partnership from "./components/Partnership";

const Villages = () => {
	return (
		<Layout>
			<Banner />
			<Partnership />
		</Layout>
	);
};

export default Villages;
