import React, { useState } from "react";
import ReactPlayer from "react-player";
import FadeIn from "../../../components/FadeIn";
import FadeInUpInline from "../../../components/FadeInUpInline";
import { cn } from "../../../utils/cn";

const VideoSection = () => {
	const [open, setOpen] = useState(false);
	return (
		<div className="bg-black">
			<FadeIn>
				<section
					style={{
						background:
							"url(/img/home/video-bg.png) no-repeat center center / cover",
					}}
					className="flex justify-center items-end py-5 md:pt-[139px] md:pb-[129px] relative"
				>
					<div className="container">
						<FadeInUpInline>
							<div className="text-center max-w-[290px] sm:max-w-[1268px] mx-auto">
								<h2 className="text-2xl md:text-[32px] md:leading-[1] mb-2 sm:mb-7 text-white font-normal max-w-[180px] sm:max-w-[333px] mx-auto">
									“You have seen my fall. <br /> Now watch my Rising!”
								</h2>
								<div className="font-normal font-ProximaNova text-center text-white text-xs md:text-[32px] mb-4 md:mb-[148px]">
									— Rumi, 13th century Afghan Poet”
								</div>
								<button
									type="button"
									className="btn"
									onClick={() => setOpen(true)}
								>
									WATCH VIDEO
								</button>
							</div>
						</FadeInUpInline>
					</div>
					<div
						className={cn(
							"fixed top-0 left-0 h-screen w-full flex justify-center items-center z-[999] duration-300 p-5",
							{
								"visible opacity-100": open,
								"invisible opacity-0": !open,
							}
						)}
					>
						<div
							className="absolute inset-0 bg-dark bg-opacity-80"
							onClick={() => setOpen(false)}
						></div>
						<div className="w-full max-w-[700px] rounded-xl overflow-hidden relative z-10">
							<ReactPlayer
								width={700}
								className="w-full"
								url="https://www.youtube.com/watch?v=LXb3EKWsInQ"
								controls
							/>
						</div>
					</div>
				</section>
			</FadeIn>
		</div>
	);
};

export default VideoSection;
