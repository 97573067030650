import React, { useEffect, useRef, useState } from "react";
import FadeInUp from "../../../components/FadeInUp";

const Banner = () => {
	const [isVisible, setIsVisible] = useState(false);
	const ref = useRef(null);
	const handleScroll = () => {
		const refBottom = ref.current.getBoundingClientRect().bottom;
		const displayBottom = window.innerHeight;
		setIsVisible(refBottom < displayBottom);
	};
	useEffect(() => {
		handleScroll();
		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, []);

	return (
		<div className="bg-black" ref={ref}>
			<section
				style={{
					background:
						"url(/img/home/banner.png) no-repeat center center / contain",
				}}
				// className="md:min-h-[calc(100vh-84px)] flex justify-center items-end pb-10 pt-20 relative lg:!bg-fixed lg:!bg-cover"
				className="md:min-h-[calc(100vh-84px)] flex justify-center items-center pb-10 pt-20 relative lg:!bg-fixed lg:!bg-cover"
			>
				<div className="container">
					<FadeInUp isVisible={isVisible}>
						<div className="text-center max-w-[1226px] mx-auto pb-8 sm:pb-20">
							<h1 className="text-[32px] max-w-[185px] sm:max-w-full mx-auto md:text-[64px] leading-[1] md:mb-[67px] text-white mb-6">
								The World’s <br /> Most Magnificient Emeralds
							</h1>
							<p className="text-sm sm:text-2xl md:text-[40px] text-white font-Orpheus max-w-[210px] sm:max-w-full mx-auto">
								Responsibly sourced from the Panjshir Valley
							</p>
						</div>
					</FadeInUp>
				</div>
				<img
					src="/img/caret-down.png"
					alt=""
					className="absolute bottom-4 left-1/2 -translate-x-1/2 animation-bounce invert w-10 md:w-[57px]"
				/>
			</section>
		</div>
	);
};

export default Banner;
