import React from "react";
import FadeInUpInline from "../../../components/FadeInUpInline";

const Banner = () => {
	return (
		<section
			style={{
				background:
					"url(/img/why-us/banner.png) no-repeat center center / cover fixed",
			}}
			className="min-h-[calc(100vh-150px)] md:min-h-[calc(100vh-84px)] flex justify-center items-center py-20 relative"
		>
			<div className="container">
				<div className="text-center pb-20">
					<FadeInUpInline>
						{/* <h1 className="text-[75px] leading-[1] lg:text-[80px] md:mb-[67px] text-white mb-[89px]"> */}
						<h1 className="text-[54px] leading-[1] lg:text-[60px] md:mb-10 text-white">
							Why Us
						</h1>
					</FadeInUpInline>
					<FadeInUpInline>
						<p className="text-xl md:text-2xl text-white font-Orpheus  max-w-[400px] mx-auto">
							We unequivocally offer the best ethically-sourced emeralds
							<br className="hidden md:inline-block" /> all from the
							Panjshir Valley, Afghanistan.
						</p>
					</FadeInUpInline>
				</div>
			</div>
			<img
				src="/img/caret-down.png"
				alt=""
				className="absolute bottom-4 left-1/2 -translate-x-1/2 animation-bounce invert"
			/>
		</section>
	);
};

export default Banner;
