import React from "react";
import FadeInUpInline from "../../../components/FadeInUpInline";

const TheGia = () => {
	return (
		<section
			style={{
				background:
					"url(/img/emerald/banner.png) no-repeat center center / cover ",
			}}
			className="py-20 pt-[221px] pb-16 md:pt-[139px] md:pb-[218px]"
		>
			<div className="container">
				<FadeInUpInline>
					<div className="text-center max-w-[290px] sm:max-w-[700px] mx-auto">
						<h2 className="text-2xl sm:text-4xl leading-[1] mb-2 sm:mb-[45px] text-white">
							“Emeralds are, most of all, about color. Emerald has been
							the standard for green among colored stones for thousands
							of years.”
						</h2>
						<div className="font-ProximaNova font-normal text-center text-white text-xl md:text-3xl">
							― The GIA
						</div>
					</div>
				</FadeInUpInline>
			</div>
		</section>
	);
};

export default TheGia;
