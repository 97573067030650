import React from "react";
import FadeInUpInline from "../../../components/FadeInUpInline";

const Banner = () => {
	return (
		<section
			style={{
				background:
					"url(/img/emerald/banner.png) no-repeat center center / cover fixed",
			}}
			className="min-h-[calc(100vh-150px)] md:min-h-[calc(100vh-84px)] flex justify-center items-center py-20 relative"
		>
			<div className="container">
				<FadeInUpInline>
					<div className="text-center max-w-[906px] mx-auto pb-20">
						{/* <h1 className="text-[75px] leading-[1] lg:text-[80px] md:mb-[67px] text-white mb-[89px]"> */}
						<h1 className="text-[55px] leading-[1] lg:text-[60px] md:mb-[67px] text-white">
							Emerald Facts
						</h1>
						<p className="text-xl md:text-2xl text-white font-Orpheus">
							Emeralds are among the rarest pecious stones{" "}
							<br className="hidden md:inline-block" /> that the earth
							has to offer—and they're rare for a reason.
						</p>
					</div>
				</FadeInUpInline>
			</div>
			<img
				src="/img/caret-down.png"
				alt=""
				className="absolute bottom-4 left-1/2 -translate-x-1/2 animation-bounce"
			/>
		</section>
	);
};

export default Banner;
