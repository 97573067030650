import { animated, useSpring } from "@react-spring/web";
import React, { useEffect, useRef, useState } from "react";
import useScreen from "../hooks/useScreen";
const FadeInUpInline = ({ fromTop, children }) => {
	const screen = useScreen();

	const [isVisible, setIsVisible] = useState(false);
	const ref = useRef();
	const handleScroll = () => {
		const refBottom = ref.current.getBoundingClientRect().top;
		const displayBottom = window.innerHeight;
		setIsVisible(refBottom < displayBottom - 100);
	};
	useEffect(() => {
		handleScroll();
		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, []);

	const styles = useSpring({
		transition: "all ease 0.4s",
		y: isVisible
			? 0
			: screen < 768
			? fromTop
				? -40
				: 40
			: fromTop
			? -100
			: 100,
		config: { mass: 5, tension: 300, friction: 40 },
	});
	return (
		<animated.div ref={ref} style={styles}>
			{children}
		</animated.div>
	);
};
export default FadeInUpInline;
