import { animated, useSpring } from "@react-spring/web";
import React, { useEffect, useRef, useState } from "react";
import useScreen from "../hooks/useScreen";
const ZoomIn = ({ children }) => {
	const screen = useScreen();

	const [isVisible, setIsVisible] = useState(false);
	const ref = useRef();
	const handleScroll = () => {
		const refBottom = ref.current.getBoundingClientRect().top;
		const displayBottom = window.innerHeight;
		setIsVisible(refBottom < displayBottom - 100);
	};
	useEffect(() => {
		handleScroll();
		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, []);

	const styles = useSpring({
		transition: "all ease 0.6s",
		scale: isVisible ? 1 : 0.4,
	});
	return (
		<animated.div ref={ref} style={styles}>
			{children}
		</animated.div>
	);
};
export default ZoomIn;
