import React from "react";
import { Link } from "react-router-dom";
import FadeInUpInline from "../../../components/FadeInUpInline";
import ZoomIn from "../../../components/ZoomIn";

const FactsAboutEmeralds = () => {
	return (
		<section
			style={{
				background:
					"url(/img/home/emerald-facts.png) no-repeat center center / cover",
			}}
			className="flex justify-center items-center py-8 md:pt-[127px] md:pb-[115px] relative"
		>
			<div className="container">
				<div className="text-center max-w-[1226px] mx-auto">
					<FadeInUpInline>
						<h1 className="text-4xl leading-[1] lg:text-[64px] md:mb-12 text-white mb-2">
							FACTS ABOUT EMERALDS
						</h1>
					</FadeInUpInline>
					<FadeInUpInline>
						<div className="max-w-[732px] mx-auto text-2xl md:text-[40px] md:leading-[1] text-white font-Orpheus font-normal">
							<p className="mb-8 md:mb-12 hidden sm:block">
								Emeralds are among the rarest precious stone. that the
								Earth has to offer.
							</p>
							<p className="mb-5">And they’re rare for a reason.</p>
						</div>
					</FadeInUpInline>
					<ZoomIn>
						<div className="max-w-[173px] sm:max-w-[786px] mx-auto">
							<img src="img/home/facts.png" className=" w-full" alt="" />
						</div>
					</ZoomIn>
					<FadeInUpInline fromTop>
						<Link to="/shop-emeralds" className="btn">
							LEARN MORE
						</Link>
					</FadeInUpInline>
				</div>
			</div>
		</section>
	);
};

export default FactsAboutEmeralds;
