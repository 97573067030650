import { animated, useSpring } from "@react-spring/web";
import React from "react";
import useScreen from "../hooks/useScreen";
const FadeInUp = ({ isVisible, children }) => {
	const screen = useScreen();

	const styles = useSpring({
		y: isVisible ? 0 : screen < 768 ? 40 : 140,
		config: { mass: 5, tension: 300, friction: 40 },
	});
	return <animated.div style={styles}>{children}</animated.div>;
};
export default FadeInUp;
