import React from 'react';

const hamburger = (
	<svg
		width="17"
		height="10"
		viewBox="0 0 17 10"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="M1 5H16" stroke="white" stroke-linecap="round" />
		<path d="M1 1H16" stroke="white" stroke-linecap="round" />
		<path d="M1 9H16" stroke="white" stroke-linecap="round" />
	</svg>
);
const lock = (
	<svg
		width="16"
		height="16"
		viewBox="0 0 33 40"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M28.1471 14.6875H25.2323V8.26328C25.2323 3.70687 21.3136 0 16.497 0C11.6804 0 7.76171 3.70687 7.76171 8.26328V14.6875H4.85294C2.17703 14.6875 0 16.7903 0 19.375V35.3125C0 37.8972 2.17703 40 4.85294 40H28.1471C30.823 40 33 37.8972 33 35.3125V19.375C33 16.7903 30.823 14.6875 28.1471 14.6875ZM10.997 8.26328C10.997 5.43 13.4643 3.125 16.497 3.125C19.5297 3.125 21.997 5.43 21.997 8.26328V14.6875H10.997V8.26328ZM29.7647 35.3125C29.7647 36.1741 29.039 36.875 28.1471 36.875H4.85294C3.96097 36.875 3.23529 36.1741 3.23529 35.3125V19.375C3.23529 18.5134 3.96097 17.8125 4.85294 17.8125H28.1471C29.039 17.8125 29.7647 18.5134 29.7647 19.375V35.3125Z"
			fill="#595955"
		/>
	</svg>
);
const secure = (
	<svg
		width="16"
		height="16"
		viewBox="0 0 40 40"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M18.3224 22.337L13.2622 17.5204L11.1069 19.7846L18.4978 26.82L29.7594 14.2813L27.4337 12.1924L18.3224 22.337Z"
			fill="#595955"
		/>
		<path
			d="M32.8513 5.09313C29.0621 4.41406 25.5726 2.34469 23.8298 1.17164C22.691 0.405156 21.3667 0 19.9999 0C18.6331 0 17.3088 0.405156 16.1701 1.17156C14.4273 2.34461 10.9377 4.41406 7.14852 5.09313C4.9043 5.49531 3.27539 7.43703 3.27539 9.71008V19.1405C3.27539 22.2559 4.14703 25.2928 5.86594 28.167C7.21078 30.4155 9.07406 32.5645 11.4041 34.5543C15.3218 37.9 19.2013 39.6446 19.3644 39.7173L19.9998 40L20.6353 39.7173C20.7985 39.6447 24.6779 37.9 28.5956 34.5543C30.9255 32.5645 32.789 30.4155 34.1338 28.167C35.8528 25.2929 36.7243 22.2559 36.7243 19.1405V9.71016C36.7245 7.43703 35.0955 5.49531 32.8513 5.09313ZM33.5984 19.1406C33.5984 23.7852 31.252 28.1543 26.6244 32.1269C23.9344 34.4361 21.2033 35.9412 20.0001 36.5521C17.2343 35.1468 6.40148 29.01 6.40148 19.1406V9.71016C6.40148 8.95273 6.94758 8.30508 7.7 8.17023C12.053 7.39016 15.969 5.07523 17.9157 3.76492C18.5366 3.34695 19.2573 3.12602 19.9999 3.12602C20.7426 3.12602 21.4634 3.34695 22.0841 3.76484C24.0309 5.07523 27.9469 7.39 32.2998 8.17008C33.0522 8.30492 33.5984 8.95258 33.5984 9.71V19.1406Z"
			fill="#595955"
		/>
	</svg>
);
const close = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 24"
		strokeWidth={1.5}
		stroke="currentColor"
		className="size-4"
	>
		<path
			strokeLinecap="round"
			strokeLinejoin="round"
			d="M6 18 18 6M6 6l12 12"
		/>
	</svg>
);

export const icons = {
	hamburger,
	lock,
	secure,
	close,
};
