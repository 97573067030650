import React from "react";
import Layout from "../../components/Layout";
import Banner from "./components/Banner";
import WhyUsSection from "./components/WhyUsSection";

const WhyUs = () => {
	return (
		<Layout>
			<Banner />
			<WhyUsSection />
		</Layout>
	);
};

export default WhyUs;
