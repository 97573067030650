import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { cn } from "../utils/cn";
import { icons } from "./icons";

const Header = () => {
	const [open, setOpen] = useState(false);
	return (
		<header className="bg-dark z-[999] relative">
			<div className="container">
				<div className="relative flex justify-center lg:justify-between items-center py-2 lg:py-0 min-h-[50px] lg:min-h-[84px]">
					<Link to="/" className="block max-w-[120px] md:max-w-[150px]">
						<img src="/img/logo.png" alt="" />
					</Link>
					<div className="hidden lg:block">
						<Menu />
					</div>
					<button
						type="button"
						className="lg:hidden absolute top-1/2 -translate-y-1/2 right-0"
						onClick={() => setOpen(!open)}
					>
						{icons.hamburger}
					</button>
				</div>
			</div>
			<div
				className={cn(
					"lg:hidden absolute top-full left-0 w-full bg-dark duration-300 origin-top",
					{
						"visible opacity-100 ": open,
						"invisible opacity-0 -translate-y-5": !open,
					}
				)}
			>
				<Menu />
			</div>
		</header>
	);
};
const Menu = () => {
	return (
		<>
			<ul className="flex flex-col lg:flex-row text-md font-normal font-Roboto gap-x-10">
				{data.map((item, index) => (
					<li key={index}>
						<NavLink
							to={item.url}
							className="text-white hover:bg-black [&.active]:bg-black px-4 py-3 lg:py-5 lg:min-h-[84px] flex items-end"
						>
							{item.name}
						</NavLink>
					</li>
				))}
			</ul>
		</>
	);
};
const data = [
	{
		name: "Shop Emeralds",
		url: "/shop-emeralds",
	},
	{
		name: "Emerald Facts",
		url: "/emerald-facts",
	},
	{
		name: "Why Us",
		url: "/why-us",
	},
	{
		name: "Village",
		url: "/village",
	},
	{
		name: "Contact",
		url: "/contact",
	},
];

export default Header;
