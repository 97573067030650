import React from 'react';
import { useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Contact from "./pages/contact/Contact";
import EmeraldFacts from "./pages/emerald-facts/EmeraldFacts";
import Home from "./pages/home/Home";
import ShopEmeralds from "./pages/shop-emeralds/ShopEmeralds";
import Villages from "./pages/villages/Villages";
import WhyUs from "./pages/why-us/WhyUs";

function App() {
	const Wrapper = ({ children }) => {
		const location = useLocation();
		useEffect(() => {
			window.scrollTo(0, 0);
		}, [location.pathname]);
		return children;
	};
	return (
		<BrowserRouter>
			<Wrapper>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/shop-emeralds" element={<ShopEmeralds />} />
					<Route path="/emerald-facts" element={<EmeraldFacts />} />
					<Route path="/why-us" element={<WhyUs />} />
					<Route path="/village" element={<Villages />} />
					<Route path="/contact" element={<Contact />} />
				</Routes>
			</Wrapper>
		</BrowserRouter>
	);
}

export default App;
