import React from "react";
import Layout from "../../components/Layout";
import Banner from "./components/Banner";

const Contact = () => {
	return (
		<Layout>
			<Banner />
		</Layout>
	);
};

export default Contact;
