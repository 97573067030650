import React from "react";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import FadeInUpInline from "../../components/FadeInUpInline";
import { icons } from "../../components/icons";
import { cn } from "../../utils/cn";

const ShopEmeralds = () => {
	const [open, setOpen] = React.useState(null);
	return (
		<>
			<main className="min-h-screen bg-[#242424]">
				<div className="container xl:max-w-[1520px] 2xl:max-w-[1520px] pb-[80px] lg:pb-[211px]">
					<div className="pt-12 md:pt-16">
						<img
							src="/img/footer-logo.png"
							className="mx-auto w-full max-w-[220px] md:max-w-[320px]"
							alt=""
						/>
						<div className="flex flex-wrap text-sm md:text-base text-center text-white justify-center mt-5 md:mt-8 mb-[60px] md:mb-[120px] font-Arial">
							<Link to="/" className="text-primary">
								Back to main site
							</Link>
							<span className="ml-2">|</span>
							<span className="mx-2">Trouble viewing?</span>
							<button type="button" className="text-primary">
								Refresh page.
							</button>
						</div>
					</div>
					<div className="flex flex-wrap justify-center gap-[38px]">
						<div className="w-full max-w-[246px] md:max-w-[150px] lg:max-w-[173px]">
							<div className="sticky top-10 max-h-[calc(100vh-80px)] overflow-y-auto text-[20px] leading-[47px] font-normal">
								{menu.map((item, index) => (
									<Link
										to=""
										className="block text-primary border-t border-[#888]"
									>
										{item.name}
									</Link>
								))}
							</div>
						</div>
						<div className="w-full sm:w-0 flex-grow max-w-[310px] md:max-w-full">
							<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 text-center text-[18px] leading-[24px] gap-[38px]">
								{data.map((item, index) => (
									<ProductCard
										setOpen={setOpen}
										item={item}
										key={index}
									/>
								))}
							</div>
						</div>
					</div>
				</div>
				<div className="container">
					<div className="pb-12 md:pb-[86px] text-center text-sm md:text-xl text-white sm:leading-[30px] font-Arial">
						<div>For sales inquiries, contact :</div>
						<div className="mb-10 md:mb-[15px]">Alex Stufflebeam</div>
						<div>WhatsApp : +1 202 802 5550</div>
						<div>AStufflebeam at PanjshirValleyEmeralds.com</div>
					</div>
				</div>
				{/* Video Open Modal */}
				<div
					className={cn(
						"fixed top-0 left-0 h-screen w-full flex justify-center items-center z-[999] duration-300 p-5",
						{
							"visible opacity-100": open,
							"invisible opacity-0": !open,
						}
					)}
				>
					<div
						className="absolute inset-0 bg-dark bg-opacity-80"
						onClick={() => setOpen(null)}
					></div>
					<div className="w-full max-w-[700px] rounded-xl overflow-hidden relative z-10">
						<ReactPlayer
							width={700}
							className="w-full"
							url={open}
							controls
						/>
					</div>
				</div>
			</main>
		</>
	);
};

export const ProductCard = ({ item, setOpen }) => {
	const [openDetails, setOpenDetails] = React.useState(false);
	return (
		<>
			<FadeInUpInline>
				<div className="bg-white relative group rounded-md group">
					<div
						className="relative overflow-hidden cursor-pointer rounded-[inherit]"
						onClick={() => setOpen(item.video)}
					>
						<img
							src={item.img}
							className="w-full duration-300 group-hover:scale-105"
							alt=""
						/>
						<button
							type="button"
							className="absolute top-2 left-2 opacity-0 group-hover:opacity-100 z-10 duration-200"
						>
							<img
								src="/img/play-button.png"
								className="w-[50px]"
								alt=""
							/>
						</button>
					</div>
					<div className="px-4 font-normal font-Arial text-black pt-[15px] pb-7 flex justify-center items-center">
						<span>{item.text}</span>
						<span className="mx-2 border-r border-l h-4 border-black"></span>
						<button
							type="button"
							onClick={() => setOpenDetails(!openDetails)}
						>
							details
						</button>
					</div>
					<div
						className={cn(
							"invisible scale-95 opacity-0 duration-200 absolute left-0 top-0 w-full h-full z-10 bg-white p-6 text-start text-base font-normal overflow-y-auto rounded-[inherit]",
							{
								"visible opacity-100 scale-100": openDetails,
							}
						)}
					>
						<button
							type="button"
							className="absolute right-3 top-3 w-6 h-6 flex items-center justify-center bg-dark text-white rounded-full"
							onClick={() => setOpenDetails(false)}
						>
							{icons.close}
						</button>
						<div className="bg-primary text-white font-normal pb-1 pt-[6px] mb-4 text-center">
							FFXYFYMAX
						</div>
						<div className="flex flex-col gap-1 px-3 2xl:px-4">
							<div>
								<strong>Weight : </strong>
								<span>12.09ct</span>
							</div>
							<div>
								<strong>Enhancement : </strong>
								<span>Excel</span>
							</div>
							<div>
								<strong>Color/Clarity : </strong>
								<span>Medium, A</span>
							</div>
							<div>
								<strong>Category : </strong>
								<span>FF</span>
							</div>
							<div>
								<strong>Dimensions : </strong>
								<span>25.1x10.9x5.4</span>
							</div>
							<div>
								<strong>Certificate : </strong>
								<Link to="" className="text-primary">
									PDF
								</Link>
							</div>
							<div>
								<strong>Interested? : </strong>
								<Link to="/contact" className="text-primary">
									Contact Us
								</Link>
							</div>
						</div>
					</div>
				</div>
			</FadeInUpInline>
		</>
	);
};

const data = [
	{
		img: "/img/majestic-emeralds/1.png",
		text: "12.09 carats",
		video: "https://www.youtube.com/watch?v=LXb3EKWsInQ",
	},
	{
		img: "/img/majestic-emeralds/2.png",
		text: "8.83 carats",
		video: "https://www.youtube.com/watch?v=LXb3EKWsInQ",
	},
	{
		img: "/img/majestic-emeralds/3.png",
		text: "6.23 carats",
		video: "https://www.youtube.com/watch?v=LXb3EKWsInQ",
	},
	{
		img: "/img/majestic-emeralds/4.png",
		text: "13.25 carats",
		video: "https://www.youtube.com/watch?v=LXb3EKWsInQ",
	},
	{
		img: "/img/majestic-emeralds/1.png",
		text: "12.09 carats",
		video: "https://www.youtube.com/watch?v=LXb3EKWsInQ",
	},
	{
		img: "/img/majestic-emeralds/2.png",
		text: "8.83 carats",
		video: "https://www.youtube.com/watch?v=LXb3EKWsInQ",
	},
	{
		img: "/img/majestic-emeralds/3.png",
		text: "6.23 carats",
		video: "https://www.youtube.com/watch?v=LXb3EKWsInQ",
	},
	{
		img: "/img/majestic-emeralds/4.png",
		text: "13.25 carats",
		video: "https://www.youtube.com/watch?v=LXb3EKWsInQ",
	},
	{
		img: "/img/majestic-emeralds/1.png",
		text: "12.09 carats",
		video: "https://www.youtube.com/watch?v=LXb3EKWsInQ",
	},
	{
		img: "/img/majestic-emeralds/2.png",
		text: "8.83 carats",
		video: "https://www.youtube.com/watch?v=LXb3EKWsInQ",
	},
	{
		img: "/img/majestic-emeralds/3.png",
		text: "6.23 carats",
		video: "https://www.youtube.com/watch?v=LXb3EKWsInQ",
	},
	{
		img: "/img/majestic-emeralds/4.png",
		text: "13.25 carats",
		video: "https://www.youtube.com/watch?v=LXb3EKWsInQ",
	},
	{
		img: "/img/majestic-emeralds/1.png",
		text: "12.09 carats",
		video: "https://www.youtube.com/watch?v=LXb3EKWsInQ",
	},
	{
		img: "/img/majestic-emeralds/2.png",
		text: "8.83 carats",
		video: "https://www.youtube.com/watch?v=LXb3EKWsInQ",
	},
	{
		img: "/img/majestic-emeralds/3.png",
		text: "6.23 carats",
		video: "https://www.youtube.com/watch?v=LXb3EKWsInQ",
	},
	{
		img: "/img/majestic-emeralds/4.png",
		text: "13.25 carats",
		video: "https://www.youtube.com/watch?v=LXb3EKWsInQ",
	},
	{
		img: "/img/majestic-emeralds/1.png",
		text: "12.09 carats",
		video: "https://www.youtube.com/watch?v=LXb3EKWsInQ",
	},
	{
		img: "/img/majestic-emeralds/2.png",
		text: "8.83 carats",
		video: "https://www.youtube.com/watch?v=LXb3EKWsInQ",
	},
	{
		img: "/img/majestic-emeralds/3.png",
		text: "6.23 carats",
		video: "https://www.youtube.com/watch?v=LXb3EKWsInQ",
	},
	{
		img: "/img/majestic-emeralds/4.png",
		text: "13.25 carats",
		video: "https://www.youtube.com/watch?v=LXb3EKWsInQ",
	},
	{
		img: "/img/majestic-emeralds/1.png",
		text: "12.09 carats",
		video: "https://www.youtube.com/watch?v=LXb3EKWsInQ",
	},
	{
		img: "/img/majestic-emeralds/2.png",
		text: "8.83 carats",
		video: "https://www.youtube.com/watch?v=LXb3EKWsInQ",
	},
	{
		img: "/img/majestic-emeralds/3.png",
		text: "6.23 carats",
		video: "https://www.youtube.com/watch?v=LXb3EKWsInQ",
	},
	{
		img: "/img/majestic-emeralds/4.png",
		text: "13.25 carats",
		video: "https://www.youtube.com/watch?v=LXb3EKWsInQ",
	},
	{
		img: "/img/majestic-emeralds/1.png",
		text: "12.09 carats",
		video: "https://www.youtube.com/watch?v=LXb3EKWsInQ",
	},
	{
		img: "/img/majestic-emeralds/2.png",
		text: "8.83 carats",
		video: "https://www.youtube.com/watch?v=LXb3EKWsInQ",
	},
	{
		img: "/img/majestic-emeralds/3.png",
		text: "6.23 carats",
		video: "https://www.youtube.com/watch?v=LXb3EKWsInQ",
	},
	{
		img: "/img/majestic-emeralds/4.png",
		text: "13.25 carats",
		video: "https://www.youtube.com/watch?v=LXb3EKWsInQ",
	},
	{
		img: "/img/majestic-emeralds/1.png",
		text: "12.09 carats",
		video: "https://www.youtube.com/watch?v=LXb3EKWsInQ",
	},
	{
		img: "/img/majestic-emeralds/2.png",
		text: "8.83 carats",
		video: "https://www.youtube.com/watch?v=LXb3EKWsInQ",
	},
	{
		img: "/img/majestic-emeralds/3.png",
		text: "6.23 carats",
		video: "https://www.youtube.com/watch?v=LXb3EKWsInQ",
	},
	{
		img: "/img/majestic-emeralds/4.png",
		text: "13.25 carats",
		video: "https://www.youtube.com/watch?v=LXb3EKWsInQ",
	},
];
const menu = [
	{
		name: "Sorting",
	},
	{
		name: "Color",
	},
	{
		name: "Clarity",
	},
	{
		name: "Enhencement",
	},
	{
		name: "Weight",
	},
	{
		name: "Size",
	},
	{
		name: "Shape",
	},
	{
		name: "Category",
	},
	{
		name: "Cert",
	},
];

export default ShopEmeralds;
