import React from "react";
import FadeInUpInline from "../../../components/FadeInUpInline";

const Banner = () => {
	return (
		<section
			style={{
				background:
					"url(/img/contact/banner.png) no-repeat center center / cover fixed",
			}}
			className="min-h-[calc(100vh-150px)] md:min-h-[calc(100vh-84px)] flex justify-center items-center py-20 relative"
		>
			<div className="container">
				<div className="text-center max-w-[906px] mx-auto">
					<FadeInUpInline>
						{/* <h1 className="text-[54px] leading-[1] lg:text-[75px] text-white"> */}
						<h1 className="text-[54px] leading-[1] lg:text-[55px] text-white">
							Contact Us
						</h1>
					</FadeInUpInline>
				</div>
			</div>
		</section>
	);
};

export default Banner;
