import React, { useEffect, useRef, useState } from "react";

const Banner = () => {
	const [isVisible, setIsVisible] = useState(false);
	const ref = useRef(null);
	const handleScroll = () => {
		const refBottom = ref.current.getBoundingClientRect().bottom;
		const displayBottom = window.innerHeight;
		setIsVisible(refBottom < displayBottom);
	};
	useEffect(() => {
		handleScroll();
		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, []);
	return (
		<section
			style={{
				background:
					"url(/img/village/banner.png) no-repeat center center / cover fixed",
			}}
			className="min-h-[calc(100vh-150px)] md:min-h-[calc(100vh-84px)] flex justify-center items-end py-10 relative"
			ref={ref}
		>
			<div className="container">
				<div className="text-center">
					{/* <h1 className="text-[54px] leading-[1] lg:text-[75px] text-white"> */}
					<h1 className="text-[54px] leading-[1] lg:text-[55px] text-white">
						PARTNER WITH A VILLAGE
					</h1>
				</div>
			</div>
		</section>
	);
};

export default Banner;
